html {
  -webkit-animation-name: bg-transition;
  animation-name: bg-transition;
  -webkit-animation-duration: 35s;
  animation-duration: 35s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

#main {
  padding: 1rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  border: 3px solid;
  background-color: white;
}

.links {
  padding: 0.5rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  border: 3px solid;
  text-align: center;
  background-color: white;
}
a:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
  color: black;
}

.container {
  line-height: 1.5;
  max-width: 22rem;
  min-width: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;

  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes color-transition {
  0% {
    border-color: #cdb4db;
  }
  20% {
    border-color: #ffc8dd;
  }
  40% {
    border-color: #dfe7fd;
  }
  60% {
    border-color: #bde0fe;
  }
  80% {
    border-color: #a2d2ff;
  }
  100% {
    border-color: #cdb4db;
  }
}

@keyframes color-transition {
  0% {
    border-color: #cdb4db;
  }
  20% {
    border-color: #ffc8dd;
  }
  40% {
    border-color: #dfe7fd;
  }
  60% {
    border-color: #bde0fe;
  }
  80% {
    border-color: #a2d2ff;
  }
  100% {
    border-color: #cdb4db;
  }
}

@-webkit-keyframes bg-transition {
  0% {
    background-color: #cdb4db;
  }
  20% {
    background-color: #ffc8dd;
  }
  40% {
    background-color: #dfe7fd;
  }
  60% {
    background-color: #bde0fe;
  }
  80% {
    background-color: #a2d2ff;
  }
  100% {
    background-color: #cdb4db;
  }
}

@keyframes bg-transition {
  0% {
    background-color: #cdb4db;
  }
  20% {
    background-color: #ffc8dd;
  }
  40% {
    background-color: #dfe7fd;
  }
  60% {
    background-color: #bde0fe;
  }
  80% {
    background-color: #a2d2ff;
  }
  100% {
    background-color: #cdb4db;
  }
}
